import { updateTripBins } from "@app/analysis/addOns/components/tripParams/tripParams.actions";
import {
    setCommercialVehicleType,
    setTravelerAttrEnabled,
    setTripAttrEnabled,
} from "@app/analysis/addOns/state/addOns.actions";
import {
    isTravelerAttributesSectionDisabled,
    isTripAttributesSectionDisabled,
} from "@app/analysis/addOns/state/addOns.selectors";
import { getAnalysisNameError } from "@app/analysis/basics/state/basics.helpers";
import { TRAVEL_MODES, ZONES_MODES } from "@app/analysis/state/analysisConfiguration.constants";
import { getAnalysisTravelModeFromCode } from "@app/analysis/state/analysisConfiguration.helpers";
import { setIs15MinuteBinsActive } from "@app/analysis/state/general/general.actions";
import {
    getIs15MinuteBinsDayPartsAvailable,
    getUiStates,
} from "@app/analysis/state/general/general.selectors";
import { setDefaultTruckDataPeriodsByCommercialVehicleType } from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodParams.actions";
import { setInitialDayParts } from "@app/analysis/timePeriods/components/dayPartParams/dayPartParams.actions";
import {
    setDataPeriodSettings,
    setTimePeriodsValidation,
} from "@app/analysis/timePeriods/state/timePeriods.actions";
import { createValidateField } from "@app/analysis/validation";
import {
    resetCalibrationZonesData,
    setZoneRole,
} from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import { ZONE_ROLES } from "@app/analysis/zones/chooseZones/state/chooseZones.constants";
import { getIsSandbox } from "@app/store/currentUser/currentUser.selector";
import { setSelectedRoads } from "@common/features/zonesManager/state/zonesManager.actions";
import { getAvailableRoadTypeIdsByNetworkType } from "@common/features/zonesManager/state/zonesManager.helpers";
import { batch } from "react-redux";

import { BASICS_ACTION_TYPES } from "./basics.actionTypes";
import { RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE_LIST } from "./basics.constants";
import { getBasics, validCalibrationCodeSelector } from "./basics.selectors";
import { BASICS_INITIAL_STATE } from "./basics.state";

export const setAnalysisName = name => ({
    type: BASICS_ACTION_TYPES.SET_NAME,
    data: name,
});

export const setAnalysisTags = tagIds => ({
    type: BASICS_ACTION_TYPES.SET_TAGS,
    data: tagIds,
});

export const setAnalysisMeasurementUnit = measurementUnit => (dispatch, getState) => {
    const state = getState();
    const { travelModeCode } = getBasics(state);

    batch(() => {
        dispatch({
            type: BASICS_ACTION_TYPES.SET_MEASUREMENT_UNIT,
            data: { measurementUnit },
        });
        dispatch(updateTripBins(travelModeCode, measurementUnit));
    });
};

export const setAnalysisCountry = country => ({
    type: BASICS_ACTION_TYPES.SET_COUNTRY,
    data: { country },
});

export const updateAnalysisData = data => ({
    type: BASICS_ACTION_TYPES.UPDATE_ANALYSIS_DATA,
    data,
});

export const setAnalysisDescription = description => ({
    type: BASICS_ACTION_TYPES.SET_DESCRIPTION,
    data: { description },
});

export const setCalibrationCode = calibrationCode => ({
    type: BASICS_ACTION_TYPES.SET_CALIBRATION_CODE,
    data: { calibrationCode },
});

export const setAvailableCalibrationCodes = availableCalibrationCodes => ({
    type: BASICS_ACTION_TYPES.SET_AVAILABLE_CALIBRATION_CODES,
    data: { availableCalibrationCodes },
});

export const setAADTCalibrationYear = aadtCalibrationYear => ({
    type: BASICS_ACTION_TYPES.SET_AADT_CALIBRATION_YEAR,
    data: { aadtCalibrationYear },
});

export const setTravelModeCode =
    ({ travelModeCode }) =>
    (dispatch, getState) => {
        let state = getState();
        const shouldEnableCvMedium = getIsSandbox(state);

        const isTruckMode = travelModeCode === TRAVEL_MODES.TRUCK.code;

        const commercialVehicleType = {
            cvMedium: shouldEnableCvMedium,
            cvHeavy: isTruckMode,
        };
        const travelModeObj = getAnalysisTravelModeFromCode(travelModeCode);
        const { is15MinuteBinsModeActive } = getUiStates(state);
        // Reset validation state of Data Periods/Data Months sections if they are not available for new travel mode
        const dataPeriodsValidation = travelModeObj.editableDataDateRanges
            ? { dataMonths: [] }
            : { dataPeriods: [] };

        batch(() => {
            if (isTruckMode) {
                dispatch(setDefaultTruckDataPeriodsByCommercialVehicleType(shouldEnableCvMedium));
            }
            dispatch({
                type: BASICS_ACTION_TYPES.SET_TRAVEL_MODE_CODE,
                data: { travelModeCode },
            });
            dispatch(setCommercialVehicleType(commercialVehicleType));
            dispatch(setTimePeriodsValidation(dataPeriodsValidation));
            if (!travelModeObj.editableDataDateRanges) {
                dispatch(setDataPeriodSettings("excludeDateRangesEnabled", false));
                dispatch(setDataPeriodSettings("excludedDataPeriods", []));
            }
        });

        state = getState();
        const newCalibrationCode = validCalibrationCodeSelector(state);

        dispatch(setCalibrationCode(newCalibrationCode));
        dispatch(resetCalibrationZonesData(newCalibrationCode));
        dispatch(updateTripBins(travelModeObj.code, getBasics(state).measurementUnit));

        // Set default roads selection
        const allRoadIds = getAvailableRoadTypeIdsByNetworkType(travelModeObj.osmNetwork);
        dispatch(setSelectedRoads(allRoadIds));

        if (isTripAttributesSectionDisabled(state)) {
            dispatch(setTripAttrEnabled(false));
        }
        if (isTravelerAttributesSectionDisabled(state)) {
            dispatch(setTravelerAttrEnabled(false));
        }

        if (is15MinuteBinsModeActive && !getIs15MinuteBinsDayPartsAvailable(state)) {
            dispatch(setIs15MinuteBinsActive(false));
            dispatch(setInitialDayParts());
        }

        const { zonesMode } = getUiStates(state);
        if (zonesMode === ZONES_MODES.CHOOSE_ZONES) {
            dispatch(setZoneRole(ZONE_ROLES.DEFAULT.accessor));
        }
    };

export const setBasicsValidation = newValidation => ({
    type: BASICS_ACTION_TYPES.SET_VALIDATION,
    data: { validation: newValidation },
});

export const updateTravelModeCode =
    ({ travelModeCode }) =>
    (dispatch, getState) => {
        RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE_LIST.forEach(({ condition, action }) => {
            const { shouldReset, updatedData } = condition({
                travelModeCode,
                state: getState(),
            });

            if (shouldReset || updatedData) {
                action(dispatch, {
                    travelModeCode,
                    state: getState(),
                    updatedData,
                });
            }
        });

        dispatch(
            setTravelModeCode({
                travelModeCode,
            }),
        );
    };

export const validateAnalysisName = () => (dispatch, getState) => {
    const state = getState();

    const { name } = getBasics(state);

    const analysisNameError = getAnalysisNameError(name);

    const newValidation = {
        name: createValidateField(analysisNameError),
    };

    dispatch(setBasicsValidation(newValidation));
};

export const validateTravelModeCode = () => (dispatch, getState) => {
    const state = getState();
    const travelModeCode = getBasics(state).travelModeCode;
    const travelModeCodeError =
        travelModeCode === "" ? "No travel mode is selected. Please select a travel mode" : "";
    const newValidation = {
        travelModeCode: createValidateField(travelModeCodeError),
    };

    dispatch(setBasicsValidation(newValidation));
};

export const resetBasicsReducer =
    (skipAnalysisNameReset = false) =>
    (dispatch, getState) => {
        if (skipAnalysisNameReset) {
            const state = getState();
            const { name, country } = getBasics(state);

            return dispatch(
                updateAnalysisData({
                    ...BASICS_INITIAL_STATE,
                    name,
                    country,
                }),
            );
        }

        return dispatch({
            type: BASICS_ACTION_TYPES.RESET_REDUCER,
        });
    };

export const setBasicsInitialData = basicsData => ({
    type: BASICS_ACTION_TYPES.SET_INITIAL_DATA,
    data: basicsData,
});
