import { faDrawPolygon } from "@fortawesome/pro-solid-svg-icons";

export const DAC_ZONES_BURDEN_CATEGORIES = {
    N_CLT: {
        code: "N_CLT",
        name: "Climate change",
    },
    N_ENY: {
        code: "N_ENY",
        name: "Energy",
    },
    N_HLTH: {
        code: "N_HLTH",
        name: "Health",
    },
    N_HSG: {
        code: "N_HSG",
        name: "Housing",
    },
    N_PLN: {
        code: "N_PLN",
        name: "Legacy pollution",
    },
    N_TRN: {
        code: "N_TRN",
        name: "Transportation",
    },
    N_WTR: {
        code: "N_WTR",
        name: "Water and wastewater",
    },
    N_WKFC: {
        code: "N_WKFC",
        name: "Workforce development",
    },
};
export type TDACZonesBurdenCategoryKey = keyof typeof DAC_ZONES_BURDEN_CATEGORIES;

export const ENHANCED_LAYER_MAX_OPACITY = 0.9;

export const ENHANCED_LAYER_MIN_OPACITY = 0.2;

export const FEMA_SUB_LAYERS = {
    NATIONAL_RISK_INDEX: {
        id: "nriLayer",
        name: "National Risk Index",
        requiredFields: ["EAL_RATNG", "SOVI_RATNG", "RESL_RATNG", "RISK_RATNG", "RISK_SCORE"],
        visField: "RISK_RATNG",
        mapLayerBins: [],
        helpLink: "https://hazards.fema.gov/nri/determining-risk",
    },
    EXPECTED_ANNUAL_LOSS: {
        id: "ealLayer",
        name: "Expected Annual Loss",
        requiredFields: ["EAL_RATNG", "EAL_SCORE", "EAL_VALA", "EAL_VALB", "EAL_VALT"],
        visField: "EAL_RATNG",
        mapLayerBins: [],
        helpLink: "https://hazards.fema.gov/nri/expected-annual-loss",
    },
    SOCIAL_VULNERABILITY: {
        id: "soviLayer",
        name: "Social Vulnerability",
        requiredFields: ["SOVI_RATNG", "SOVI_SCORE", "SOVI_SPCTL"],
        visField: "SOVI_RATNG",
        mapLayerBins: [],
        helpLink: "https://hazards.fema.gov/nri/social-vulnerability",
    },
    COMMUNITY_RESILIENCE: {
        id: "reslLayer",
        name: "Community Resilience",
        requiredFields: ["RESL_RATNG", "RESL_SCORE", "RESL_VALUE"],
        visField: "RESL_RATNG",
        mapLayerBins: [],
        helpLink: "https://hazards.fema.gov/nri/community-resilience",
    },
    COASTAL_FLOODING: {
        id: "cfldLayer",
        name: "Coastal Flooding",
        requiredFields: ["CFLD_AFREQ", "CFLD_EALB", "CFLD_EALPE", "CFLD_EALT"],
        visField: "CFLD_AFREQ",
        mapLayerBins: [0.5, 2, 3.5, 5.5],
        helpLink: "https://hazards.fema.gov/nri/coastal-flooding",
    },
    RIVERINE_FLOODING: {
        id: "rfldLayer",
        name: "Riverine Flooding",
        requiredFields: [
            "RFLD_AFREQ",
            "RFLD_EALA",
            "RFLD_EALB",
            "RFLD_EALPE",
            "RFLD_EALT",
            "RFLD_EVNTS",
        ],
        visField: "RFLD_AFREQ",
        mapLayerBins: [1, 3, 5, 8, 13],
        helpLink: "https://hazards.fema.gov/nri/riverine-flooding",
    },
    WINTER_WEATHER: {
        id: "wntwLayer",
        name: "Winter Weather",
        requiredFields: [
            "WNTW_AFREQ",
            "WNTW_EALA",
            "WNTW_EALB",
            "WNTW_EALPE",
            "WNTW_EALT",
            "WNTW_EVNTS",
        ],
        visField: "WNTW_AFREQ",
        mapLayerBins: [2, 5, 12],
        helpLink: "https://hazards.fema.gov/nri/winter-weather",
    },
};
export const FEMA_SUB_LAYERS_LIST = Object.values(FEMA_SUB_LAYERS);
export type TFEMASubLayer = typeof FEMA_SUB_LAYERS[keyof typeof FEMA_SUB_LAYERS];

export const ENHANCED_LAYERS = {
    DAC_ZONES: {
        code: "dacZones",
        layerCode: "DAC_ZONES",
        display: "DAC Layer",
        icon: faDrawPolygon,
        subLayers: [],
        featureIdProperty: "FID",
        preferenceProperty: "",
        features: [],
        style: {
            color: "#C970FF",
            hoverColor: "#FFFFFF",
            selectColor: "rgba(255, 255, 255, 0.4)",
            selectLineColor: "rgba(255, 255, 255, 0.8)",
            fillOpacity: 0.4,
            lineWidth: 3,
        },
        helpIcon: "enhanced-layers-dac-zones-info-icon",
        helpLink: "https://screeningtool.geoplatform.gov/",
    },
    NRI_ZONES: {
        code: "nriZones",
        layerCode: "NRI_ZONES",
        display: "National Risk Index",
        icon: faDrawPolygon,
        subLayers: [
            FEMA_SUB_LAYERS.NATIONAL_RISK_INDEX,
            FEMA_SUB_LAYERS.EXPECTED_ANNUAL_LOSS,
            FEMA_SUB_LAYERS.SOCIAL_VULNERABILITY,
            FEMA_SUB_LAYERS.COMMUNITY_RESILIENCE,
        ],
        featureIdProperty: "OBJECTID",
        preferenceProperty: "nriSubLayer",
        features: [],
        style: {
            color: "#F52441",
            hoverColor: "#FFFFFF",
            selectColor: "rgba(255, 255, 255, 0.4)",
            selectLineColor: "rgba(255, 255, 255, 0.8)",
            fillOpacity: 0.4,
            lineWidth: 3,
        },
        helpIcon: "enhanced-layers-nri-zones-info-icon",
        helpLink: "https://hazards.fema.gov/nri/",
    },
    NHL_ZONES: {
        code: "nhlZones",
        layerCode: "NHL_ZONES",
        display: "Natural Hazard",
        icon: faDrawPolygon,
        subLayers: [
            FEMA_SUB_LAYERS.COASTAL_FLOODING,
            FEMA_SUB_LAYERS.RIVERINE_FLOODING,
            FEMA_SUB_LAYERS.WINTER_WEATHER,
        ],
        featureIdProperty: "OBJECTID",
        preferenceProperty: "nhlSubLayer",
        features: [],
        style: {
            color: "#1073E9",
            hoverColor: "#FFFFFF",
            selectColor: "rgba(255, 255, 255, 0.4)",
            selectLineColor: "rgba(255, 255, 255, 0.8)",
            fillOpacity: 0.4,
            lineWidth: 3,
        },
        helpIcon: "enhanced-layers-nhl-zones-info-icon",
        helpLink: "https://hazards.fema.gov/nri/natural-hazards",
    },
};
export const ENHANCED_LAYERS_LIST = Object.values(ENHANCED_LAYERS);
export type TEnhancedLayer = typeof ENHANCED_LAYERS[keyof typeof ENHANCED_LAYERS];

export enum ERiskLevels {
    VERY_HIGH = "Very High",
    RELATIVELY_HIGH = "Relatively High",
    HIGH = "High",
    MODERATE = "Moderate",
    RELATIVELY_MODERATE = "Relatively Moderate",
    LOW = "Low",
    RELATIVELY_LOW = "Relatively Low",
    VERY_LOW = "Very Low",
}

export const FEMA_LAYER_NRI_LEGEND_CONFIG = {
    BASE_COLORS: {
        [ENHANCED_LAYERS.NHL_ZONES.code]: "16,115,233",
        [ENHANCED_LAYERS.NRI_ZONES.code]: "245, 36, 65",
    },
    OPACITIES: [0.9, 0.7, 0.5, 0.3, 0.2],
    LABELS: [
        ERiskLevels.VERY_HIGH,
        ERiskLevels.RELATIVELY_HIGH,
        ERiskLevels.RELATIVELY_MODERATE,
        ERiskLevels.RELATIVELY_LOW,
        ERiskLevels.VERY_LOW,
    ],
};

const BASE_COLORS = {
    [ENHANCED_LAYERS.NHL_ZONES.code]: "16,115,233",
    [ENHANCED_LAYERS.NRI_ZONES.code]: "245, 36, 65",
};

export const FEMA_LAYER_NHL_LEGEND_CONFIG = {
    [FEMA_SUB_LAYERS.COASTAL_FLOODING.id]: {
        BASE_COLORS,
        OPACITIES: [0.9, 0.7, 0.5, 0.3, 0.2],
        LABELS: ["> 5.5", "3.5-5.5", "2.0-3.5", "0.5-2.0", "0-0.5"],
    },
    [FEMA_SUB_LAYERS.RIVERINE_FLOODING.id]: {
        BASE_COLORS,
        OPACITIES: [0.9, 0.7, 0.5, 0.3, 0.2, 0.1],
        LABELS: ["> 13", "8-13", "5-8", "3-5", "1-3", "0-1"],
    },
    [FEMA_SUB_LAYERS.WINTER_WEATHER.id]: {
        BASE_COLORS,
        OPACITIES: [0.9, 0.7, 0.5, 0.3],
        LABELS: ["> 12", "5-12", "2-5", "0-2"],
    },
};
