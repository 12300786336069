import { hasRouteOptionsChanged } from "@app/analysis/addOns/components/routes/routes.helpers";
import {
    ANALYSES_WITH_CVD_LIMITED_TRAVELER_ATTRS,
    TRAVELER_PARAMS_US_CENSUS_2020_LIST,
} from "@app/analysis/addOns/state/addOns.constants";
import { DAY_TYPES_KINDS } from "@app/analysis/timePeriods/state/timePeriods.constants";
import {
    getDayFromCode,
    getDayFromShortName,
    getHasBikePedAnalysisInvalidDataPeriods,
    getHasInvalidGMCVDTimePeriods,
    getHasInvalidTTApiTimePeriods,
} from "@app/analysis/timePeriods/state/timePeriods.helpers";
import { getIsReusedIntersection } from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.helpers";
import { getZoneKindIdsWithOldZones } from "@app/analysis/zones/chooseZones/state/chooseZones.helpers";
import {
    ANALYSIS_TYPES,
    MODES_OF_TRAVEL,
    ORG_COUNTRIES,
} from "@common/constants/analysis.constants";
import { getIsBikeOrPedTravelMode, getIsTMCAnalysis } from "@common/helpers/analysis";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { searchFilter } from "@common/utils/searchFilter";
import { isEqual } from "lodash-es";
import moment from "moment";

import {
    ALL_VEHICLES_TRAVEL_MODES_LIST,
    ANALYSIS_CONSTANTS,
    AVAILABLE_ALL_VEHICLES_BY_WEIGHT_YEARS,
    CALIBRATION_CODE_VOLUME,
    CALIBRATIONS,
    CALIBRATIONS_LIST,
    CREATE_ANALYSIS_TYPES,
    CREATE_ANALYSIS_TYPES_LIST,
    DEPRECATED_ANALYSIS_TYPES_LIST,
    HISTORICAL_DATA_YEARS,
    TRAVEL_MODES,
    TRAVEL_MODES_LIST,
    ZONES_MODES,
} from "./analysisConfiguration.constants";

const DEFAULT_BIN_SEPARATORS = { binSeparator: " to ", joinSeparator: ", " };

export const binsToString = (
    bins = [],
    { binSeparator, joinSeparator } = DEFAULT_BIN_SEPARATORS,
) => {
    const sortedBins = [...bins].sort((a, b) => a.start - b.start);
    return sortedBins
        .map(bin => {
            if (bin.onlyStartValue) return bin.start;
            if (!bin.end) return `${bin.start}+`;
            return `${bin.start}${binSeparator}${bin.end}`;
        })
        .join(joinSeparator);
};

export const binsToArray = (bins = "", onlyStartValue) => {
    if (onlyStartValue)
        return bins
            .split(",")
            .map(start => ({ start: Number(start), end: "", onlyStartValue: true }));

    return bins.split(",").map(bin => {
        if (bin.includes("+")) {
            return {
                start: Number(bin.split("+")[0]),
            };
        }

        const binRange = bin.split("-");

        return {
            start: Number(binRange[0]),
            end: Number(binRange[1]),
        };
    });
};

export const optionSearchFilter = (option, search) =>
    searchFilter(option.title, search) ||
    searchFilter(option.shortTitle, search) ||
    searchFilter(option.description, search);

export const getCurrentTabIndex = (currentTab, tabList) =>
    tabList.findIndex(tab => tab.id === currentTab);

export const getProjectTypeValueForCreatePayload = ({ analysisType, aadtYear }) => {
    if (analysisType.code === CREATE_ANALYSIS_TYPES.AADT.code) {
        return `Estimated_${aadtYear}_AADT`;
    } else if (analysisType.code === CREATE_ANALYSIS_TYPES.TT_TMC.code) {
        return CREATE_ANALYSIS_TYPES.TMC.code;
    }
    return analysisType.code;
};

export const getTravelModeValueForCreatePayload = ({ travelModeCode }) => {
    // BE currently doesn't support creating analyses with 'All_Vehicles_Only' mode
    if (travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code) {
        return MODES_OF_TRAVEL.ALL_VEHICLES.code;
    }
    return travelModeCode;
};

export const getAnalysisTravelModeFromCode = travelModeCode => {
    const allVehicleMode = ALL_VEHICLES_TRAVEL_MODES_LIST.find(
        mode => mode.code === travelModeCode,
    );

    if (allVehicleMode) {
        return allVehicleMode;
    }
    return TRAVEL_MODES_LIST.find(mode => mode.code === travelModeCode);
};

export const getAnalysisTypeHelpOptionFromCode = analysisTypeCode => {
    return ANALYSIS_CONSTANTS.analysisTypeHelp.options.find(option => {
        const nestedType = option.analysisTypesGroup?.find(
            analysisType => analysisType.code === analysisTypeCode,
        );

        return !!nestedType || option.code === analysisTypeCode;
    });
};

// Returns true if the analysis type only has a single zone set type input.
export const analysisTypeHasSingleZoneSet = analysisTypeCode => {
    return [
        CREATE_ANALYSIS_TYPES.ZA.code,
        CREATE_ANALYSIS_TYPES.ODG.code,
        CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.code,
        CREATE_ANALYSIS_TYPES.AADT.code,
        CREATE_ANALYSIS_TYPES.SEGMENT.code,
        CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
        CREATE_ANALYSIS_TYPES.CONGESTION.code,
        CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code,
    ].includes(analysisTypeCode);
};

// Returns true if the analysis type only has destination zone set type input.
export const analysisTypeHasDestinationZoneSet = analysisTypeCode => {
    return [
        CREATE_ANALYSIS_TYPES.OD.code,
        CREATE_ANALYSIS_TYPES.ODMF.code,
        CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.code,
        CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
    ].includes(analysisTypeCode);
};

// Returns true if the analysis type only has middle-filter zone set type input.
export const analysisTypeHasMiddleZoneSet = analysisTypeCode => {
    return analysisTypeCode === CREATE_ANALYSIS_TYPES.ODMF.code;
};

export const analysisTypeHasGeography = analysisTypeCode => {
    return analysisTypeCode === CREATE_ANALYSIS_TYPES.ODG.code;
};

// Check that analysis type is supported by Lite subscription package
export const isLiteSubscriptionAnalysisType = analysisTypeCode => {
    return [
        CREATE_ANALYSIS_TYPES.ZA.code,
        CREATE_ANALYSIS_TYPES.OD.code,
        CREATE_ANALYSIS_TYPES.AADT.code,
    ].includes(analysisTypeCode);
};

export const getAnalysisTypeFromCode = analysisTypeCode => {
    return CREATE_ANALYSIS_TYPES_LIST.find(type => type.code === analysisTypeCode);
};

export const isAADTAnalysisType = analysisTypeId => {
    const matchedAADT = /Estimated_(.+)_AADT/.exec(analysisTypeId);
    return !!matchedAADT;
};

export const getAnalysisType = (analysisTypeId, travelModeCode) => {
    if (
        analysisTypeId === CREATE_ANALYSIS_TYPES.TMC.code &&
        travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code
    ) {
        return CREATE_ANALYSIS_TYPES.TT_TMC;
    } else if (isAADTAnalysisType(analysisTypeId)) {
        // Use generic AADT analysis type for all AADT analyses
        return CREATE_ANALYSIS_TYPES.AADT;
    }
    return getAnalysisTypeFromCode(analysisTypeId);
};

export const getAnalysisFeatureName = analysisTypeObj => analysisTypeObj?.featureName || null;

export const getIsAnalysisTypeSupportTravelMode = (travelModeCode, analysisTypeCode) => {
    return getAnalysisTypeFromCode(analysisTypeCode).travelModes.some(
        travelMode => travelMode.code === travelModeCode,
    );
};

export const getIsAnalysisTypeSupportOption = (optionCode, analysisTypeCode) => {
    return getAnalysisTypeFromCode(analysisTypeCode).addOns.some(option => {
        return option.code === optionCode;
    });
};

export const getIsTravelModeSupportOption = (travelModeCode, optionCode) => {
    return getAnalysisTravelModeFromCode(travelModeCode).options.some(option => {
        return option === optionCode;
    });
};

export const shouldResetTravelerAttr = (analysisTypeCode, travelerAttribute) => {
    return (
        !getIsAnalysisTypeSupportOption("travelerAttributes", analysisTypeCode) &&
        travelerAttribute
    );
};

export const shouldResetTripAttr = (analysisTypeCode, tripAttribute) => {
    return !getIsAnalysisTypeSupportOption("tripAttributes", analysisTypeCode) && tripAttribute;
};

export const shouldResetRouteOptions = (analysisTypeCode, routeOptions) => {
    return (
        !getIsAnalysisTypeSupportOption("topRoutes", analysisTypeCode) &&
        hasRouteOptionsChanged(routeOptions)
    );
};

export const getAnalysisCalibrationCode = analysisData => {
    const { output_type_id: outputTypeId } = analysisData;
    const calibration = CALIBRATIONS_LIST.find(_calibration => {
        if (_calibration.aadtCalibrations) {
            return !!_calibration.aadtCalibrations.find(
                aadtCalibration => aadtCalibration.id === outputTypeId,
            );
        }
        return _calibration.id === outputTypeId;
    });

    return calibration?.code || "";
};

export const getIsIPFCalibrationSelected = calibrationCode =>
    calibrationCode === CALIBRATIONS.IPF.code;

export const getUniqueZoneDetailsPayload = selectedZones => {
    // Zone roles which should be considered for zone quota count
    const analysisZoneRoles = ["oz", "dz", "mfz"];

    return Object.keys(selectedZones).reduce((res, zoneKind) => {
        const zoneIds = selectedZones[zoneKind].filter(zone => {
            const zone_roles = Object.keys(zone.zone_roles);
            return zone_roles.some(zoneRole => analysisZoneRoles.includes(zoneRole));
        });

        return {
            ...res,
            [zoneKind]: zoneIds,
        };
    }, {});
};

export const getIsEnableUpsampling = (isUpsamplingAvailable, is15MinuteBinsModeActive) =>
    isUpsamplingAvailable && is15MinuteBinsModeActive;

export const getAnalysisZonesMode = analysisData => {
    const { project_type: analysisType, intersection_zones: intersectionZones } = analysisData;

    if (getIsTMCAnalysis(analysisType)) {
        const hasReusedIntersections =
            intersectionZones.length &&
            intersectionZones.every(zone => getIsReusedIntersection(zone));

        return hasReusedIntersections ? ZONES_MODES.ZONE_SETS : ZONES_MODES.CHOOSE_ZONES;
    }

    return ZONES_MODES.CHOOSE_ZONES;
};

export const getIsAllTripAttributesDisabled = (analysisData, isUSCensus2020Analysis) =>
    isUSCensus2020Analysis
        ? TRAVELER_PARAMS_US_CENSUS_2020_LIST.every(attr => !analysisData[attr.binTypeAccessor])
        : true;

export const getHasBikePedAnalysisInvalidOutputTypeOrTimePeriods = analysis =>
    !getIsBikeOrPedTravelMode(analysis.travel_mode_type)
        ? {
              invalidTimePeriods: false,
              invalidOutputType: false,
          }
        : {
              invalidTimePeriods: getHasBikePedAnalysisInvalidDataPeriods(analysis),
              invalidOutputType: getAnalysisCalibrationCode(analysis) === CALIBRATIONS.INDEX.code,
          };

export const getAvailableDataMonthsForCopyWithBikePedMode = ({
    dataMonths,
    availableDataMonths,
    travelModeType,
    country,
    isCopyOrDraft,
}) => {
    const isBikePed = getIsBikeOrPedTravelMode(travelModeType);

    if (!isCopyOrDraft || !isBikePed) return dataMonths;

    const getKey = data => `${data.year}-${data.month}`;

    const availableDataMonthsMap = availableDataMonths[travelModeType].reduce(
        (result, _dataMonth) => {
            if (_dataMonth.country !== country) return result;

            return {
                ...result,
                [getKey(_dataMonth)]: _dataMonth,
            };
        },
        {},
    );

    return dataMonths.filter(_dataMonth => availableDataMonthsMap[getKey(_dataMonth)]);
};

export const getCopyAnalysisValidation = (
    analysisData,
    { availableDataMonths, gmCVDFeatureState },
) => {
    const bikePedAnalysis = getHasBikePedAnalysisInvalidOutputTypeOrTimePeriods(analysisData);
    const zoneKindIdsWithOldZones = getZoneKindIdsWithOldZones(analysisData);
    const hasInvalidTTApiTimePeriods = getHasInvalidTTApiTimePeriods(
        analysisData,
        availableDataMonths,
    );
    const gmCVDTimePeriodsValidation = getHasInvalidGMCVDTimePeriods(
        analysisData,
        availableDataMonths,
        gmCVDFeatureState,
    );
    const warnings = {};
    if (bikePedAnalysis.invalidTimePeriods) {
        warnings.bikePedTimePeriods = {
            value: bikePedAnalysis.invalidTimePeriods,
            message:
                "Available Bike and Pedestrian data months have been updated. You will need to reselect your desired data period.",
        };
    }
    if (bikePedAnalysis.invalidOutputType) {
        warnings.bikePedOutputType = {
            value: bikePedAnalysis.invalidOutputType,
            message: "Available output types have been updated since this analysis was created.",
        };
    }
    if (gmCVDTimePeriodsValidation.isInvalid) {
        warnings.gmCVDTimePeriods = {
            value: true,
            message:
                "The selected data months for this analysis are no longer available. You will need to reselect your desired data period.",
            defaultDataPeriods: gmCVDTimePeriodsValidation.defaultDataPeriods,
            defaultDataMonths: gmCVDTimePeriodsValidation.defaultDataMonths,
        };
    }
    if (hasInvalidTTApiTimePeriods) {
        warnings.ttApiTimePeriods = {
            value: true,
            message:
                "The selected data months for this analysis are no longer available. You will need to reselect your desired data period.",
        };
    }
    if (zoneKindIdsWithOldZones.length) {
        warnings.zoneKindIdsWithOldZones = {
            value: zoneKindIdsWithOldZones,
            message:
                "The Zone Library has upgraded since this analysis was made. Confirming will still require new zones.",
        };
    }
    return { hasWarnings: !!Object.keys(warnings).length, warnings };
};

export const getAnalysisCustomDateRanges = (analysisData, dataPeriods, excludedDataPeriods) => {
    const analysisType = isAADTAnalysisType(analysisData.project_type)
        ? CREATE_ANALYSIS_TYPES.AADT
        : getAnalysisType(analysisData.project_type, analysisData.travel_mode_type);
    const hasCustomRanges =
        analysisType.supportsCustomRanges && analysisData.date_format === "date_ranges";

    if (!hasCustomRanges) {
        return { hasCustomRanges, customRanges: [] };
    }
    const customExcludedDays = excludedDataPeriods.reduce((res, dateRange) => {
        const date = moment(dateRange.startDate);
        while (date <= moment(dateRange.endDate)) {
            res.push(date.valueOf());
            date.add(1, "day");
        }
        return res;
    }, []);
    const customRanges = dataPeriods.map(dataPeriod => {
        const excludedDays = customExcludedDays.filter(excludedDay =>
            moment(excludedDay).isBetween(dataPeriod.startDate, dataPeriod.endDate),
        );

        return {
            ...dataPeriod,
            excludedDays,
            isSelected: true,
        };
    });

    return { hasCustomRanges, customRanges };
};

export const getIsUSTruckVolumeAnalysis = (country, travelModeCode, calibrationCode) =>
    country === ORG_COUNTRIES.US.code &&
    travelModeCode === TRAVEL_MODES.TRUCK.code &&
    calibrationCode === CALIBRATIONS.VOLUME.code;

export const getIsAnalysisWithDeprecatedTravelModes = (analysisTypeCode, travelModeCode) => {
    const analysisType = getAnalysisType(analysisTypeCode, travelModeCode);

    return !!analysisType.deprecatedTravelModes?.find(
        travelMode => travelMode.code === travelModeCode,
    );
};

export const getIsCVDAnalysisWithLimitedTravelerAttrs = (analysisTypeCode, travelModeCode) => {
    return (
        ANALYSES_WITH_CVD_LIMITED_TRAVELER_ATTRS.includes(analysisTypeCode) &&
        travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code
    );
};

export const getCopiedAnalysisName = (name, analyses) => {
    const copiedAnalysisName = `Copy of ${name}`;
    const findAnalysisName = analysisName =>
        analyses.some(analysis => analysis.project_name.toLowerCase() === analysisName);
    let newAnalysisName = copiedAnalysisName;
    let isNameExists = findAnalysisName(newAnalysisName.toLowerCase());
    let i = 0;

    while (isNameExists) {
        i++;
        newAnalysisName = `${copiedAnalysisName} (${i})`;
        isNameExists = findAnalysisName(newAnalysisName.toLowerCase());
    }

    return newAnalysisName;
};

export const getIsLightningAnalysis = analysisTypeId => {
    const isAADT = isAADTAnalysisType(analysisTypeId);

    if (isAADT) return CREATE_ANALYSIS_TYPES.AADT.isLightning;

    return CREATE_ANALYSIS_TYPES_LIST.find(analysisTypeData => {
        return analysisTypeData.code === analysisTypeId;
    })?.isLightning;
};

export const getIsDeprecatedAnalysisType = analysisTypeId => {
    return DEPRECATED_ANALYSIS_TYPES_LIST.some(analysisType =>
        [analysisType.id, analysisType.name].includes(analysisTypeId),
    );
};

// Returns Create Analysis URL for provided analysis type id
export const getCreateAnalysisUrlPath = (analysisTypeId, isLightning) => {
    const analysisType = getAnalysisType(analysisTypeId);
    const urlPath = analysisType?.urlPath || analysisTypeId;
    return isLightning ? `/lightning-analysis/${urlPath}` : `/analysis/${urlPath}`;
};

// Returns analysis type code from provided URL path
export const getAnalysisTypeCodeFromUrl = urlPath => {
    const analysisType = CREATE_ANALYSIS_TYPES_LIST.find(analysis => analysis.urlPath === urlPath);
    return analysisType?.code || urlPath;
};

export const getPartialPeriodDisplayName = partialPeriod => {
    const firstDay = moment(partialPeriod.startDate).date();
    const lastDay = moment(partialPeriod.endDate).date();
    const monthYearPart = moment(partialPeriod.startDate).format("MMM'YY");

    return `${firstDay}-${lastDay} ${monthYearPart}`;
};

export const isSameMonth = (compareDate, year, month) =>
    moment([year, month - 1]).isSame(compareDate, "month");

export const getPartialPeriodsForMonth = (partialPeriods, year, month) =>
    partialPeriods.filter(partialPeriod => isSameMonth(partialPeriod.startDate, year, month));

export const isPartialDataPeriod = (partialPeriods, year, month) => {
    return partialPeriods.some(
        dataPeriod =>
            isSameMonth(dataPeriod.startDate, year, month) ||
            isSameMonth(dataPeriod.endDate, year, month),
    );
};

export const getAvailablePartialDataPeriods = (availableDataMonths, partialDataPeriods) => {
    const _availableDataMonths = availableDataMonths.reduce(
        (res, monthData) => ({
            ...res,
            [monthData.year]: {
                ...res[monthData.year],
                [monthData.month]: true,
            },
        }),
        {},
    );

    return partialDataPeriods.filter(dataPeriods => {
        const month = moment(dataPeriods.startDate).month() + 1;
        const year = moment(dataPeriods.startDate).year();

        return _availableDataMonths[year]?.[month];
    });
};

export const makeDataPeriodItem = (dataPeriod, partialDataPeriods) => {
    const dataPeriods = [];
    if (isPartialDataPeriod(partialDataPeriods, dataPeriod.year, dataPeriod.month)) {
        const partialPeriodsForMonth = getPartialPeriodsForMonth(
            partialDataPeriods,
            dataPeriod.year,
            dataPeriod.month,
        );

        partialPeriodsForMonth.forEach(partialPeriod => {
            dataPeriods.push({
                ...dataPeriod,
                display_name: getPartialPeriodDisplayName(partialPeriod),
            });
        });
    } else {
        dataPeriods.push(dataPeriod);
    }
    return dataPeriods;
};

// Collapse contiguous months into ranges
export const collapseMonthsToRanges = (rawDataPeriods, partialDataPeriods) => {
    if (!rawDataPeriods || !rawDataPeriods.length) {
        return [];
    }

    const createMonthIdx = dataPeriod => dataPeriod.year * 12 + (dataPeriod.month - 1);
    const createStartDate = dataPeriod => {
        const mt = moment([dataPeriod.year, dataPeriod.month - 1]);
        return mt.valueOf();
    };
    const createEndDate = (dataPeriod, partialPeriods) => {
        if (isPartialDataPeriod(partialPeriods, dataPeriod.year, dataPeriod.month)) {
            // Find partial periods with same year&month of current dataPeriod
            const samePartialPeriods = partialPeriods.filter(period => {
                const date = new Date(period.startDate);
                return (
                    date.getFullYear() === dataPeriod.year &&
                    date.getMonth() + 1 === dataPeriod.month
                );
            });
            const partialPeriodEnd = samePartialPeriods[samePartialPeriods.length - 1].endDate;

            return moment(partialPeriodEnd).endOf("day").valueOf();
        }
        const mt = moment([dataPeriod.year, dataPeriod.month - 1]).endOf("month");
        return mt.valueOf();
    };

    const ranges = [];
    let monthIdx = createMonthIdx(rawDataPeriods[0]);
    let startDate = createStartDate(rawDataPeriods[0]);
    let endDate = createEndDate(rawDataPeriods[0], partialDataPeriods);

    // rawDataPeriod.month will be > 0
    let openRange = true;
    rawDataPeriods.forEach((rawDataPeriod, index) => {
        const thisMonthIdx = createMonthIdx(rawDataPeriod);
        if (thisMonthIdx > monthIdx + 1) {
            // More than a month gap, finish the period
            ranges.push({
                startDate: startDate,
                endDate: endDate,
            });
            startDate = createStartDate(rawDataPeriod);
            openRange = false;

            if (index === rawDataPeriods.length - 1) {
                ranges.push({
                    startDate,
                    endDate: createEndDate(rawDataPeriod, []),
                });
            }
        } else {
            openRange = true;
        }
        endDate = createEndDate(rawDataPeriod, partialDataPeriods);
        monthIdx = thisMonthIdx;
    });

    // Finish up last item as needed
    if (openRange) {
        const dataPeriod = rawDataPeriods[rawDataPeriods.length - 1];
        ranges.push({
            startDate,
            endDate: createEndDate(dataPeriod, partialDataPeriods),
        });
        openRange = false;
    }

    return ranges;
};

export const enabledForCountry = (dataPeriod, country) => {
    return !country || dataPeriod.country === country;
};

export const enabledForHistoricalYear = (dataPeriod, availableDataYears) => {
    if (HISTORICAL_DATA_YEARS.includes(dataPeriod.year)) {
        return availableDataYears.includes(dataPeriod.year);
    }

    return true;
};

export const enabledForAnalysisType = (dataPeriod, analysisType) => {
    // INST-16871: Traffic Diagnostics analyses should disallow dates in 2020 or later
    if (analysisType === CREATE_ANALYSIS_TYPES.CONGESTION.code) {
        return dataPeriod.year < 2020;
    }

    return true;
};

export const enabledForTravelModeAndCalibrationCombination = ({
    dataPeriod,
    travelModeCode,
    calibrationCode,
    isOnlyHeavyDuty,
    isGMCVDEnabled,
}) => {
    // Volume calibration code should use has_*_volume
    if (calibrationCode === CALIBRATION_CODE_VOLUME) {
        switch (travelModeCode) {
            case MODES_OF_TRAVEL.ALL_VEHICLES.code:
            case MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code:
                return dataPeriod.has_lbsv2_volume;
            case MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code:
                // GM CVD months should be included only if appropriate feature flag is enabled
                return dataPeriod.has_gm_metric
                    ? dataPeriod.has_cvd_volume && isGMCVDEnabled
                    : dataPeriod.has_cvd_volume;
            case MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code:
                return dataPeriod.has_tt_volume;
            case MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code:
                return dataPeriod.has_tt_api_volume;
            case MODES_OF_TRAVEL.TRUCK.code:
                return isOnlyHeavyDuty
                    ? dataPeriod.has_gps_heavy_metric
                    : dataPeriod.has_gps_volume;
            case MODES_OF_TRAVEL.BICYCLE.code:
                return dataPeriod.has_bike_volume;
            case MODES_OF_TRAVEL.PEDESTRIAN.code:
                return dataPeriod.has_ped_volume;
            case MODES_OF_TRAVEL.BUS.code:
                return dataPeriod.has_bus_volume;
            case MODES_OF_TRAVEL.RAIL.code:
                return dataPeriod.has_rail_volume;
            default:
                return false;
        }
    }

    // All other calibration codes should use has_*_metric
    switch (travelModeCode) {
        case MODES_OF_TRAVEL.ALL_VEHICLES.code:
        case MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code:
            return dataPeriod.has_lbsv2_metric;
        case MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code:
            // GM CVD months should be included only if appropriate feature flag is enabled
            return dataPeriod.has_gm_metric
                ? dataPeriod.has_cvd_metric && isGMCVDEnabled
                : dataPeriod.has_cvd_metric;
        case MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code:
            return dataPeriod.has_tt_metric;
        case MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code:
            return dataPeriod.has_tt_api_metric;
        case MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code:
            return AVAILABLE_ALL_VEHICLES_BY_WEIGHT_YEARS.includes(dataPeriod.year);
        case MODES_OF_TRAVEL.TRUCK.code:
            return isOnlyHeavyDuty ? dataPeriod.has_gps_heavy_metric : dataPeriod.has_gps_metric;
        case MODES_OF_TRAVEL.BICYCLE.code:
            return dataPeriod.has_bike_metric;
        case MODES_OF_TRAVEL.PEDESTRIAN.code:
            return dataPeriod.has_ped_metric;
        case MODES_OF_TRAVEL.BUS.code:
            return dataPeriod.has_bus_metric;
        case MODES_OF_TRAVEL.RAIL.code:
            return dataPeriod.has_rail_metric;
        default:
            return false;
    }
};

export const convertTMCRecentToTMCHistoric = (analysis, defaultDataPeriods) => {
    return {
        ...analysis,
        data_periods: [],
        date_ranges: defaultDataPeriods[MODES_OF_TRAVEL.ALL_VEHICLES.code].map(dateRange => ({
            start_date: moment(dateRange.startDate).format("MM/DD/YYYY"),
            end_date: moment(dateRange.endDate).format("MM/DD/YYYY"),
        })),
        project_type: ANALYSIS_TYPES.TMC.id,
        travel_mode_type: MODES_OF_TRAVEL.ALL_VEHICLES.code,
    };
};

export const convertTMCHistoricToTMCRecent = (analysis, defaultDataPeriods) => {
    const findDayTypeByRangeNames = (startDay, endDay) => {
        return [
            ...DAY_TYPES_KINDS.DAY_RANGES.defaultOptions,
            ...DAY_TYPES_KINDS.INDIVIDUAL_DAYS.defaultOptions,
        ].find(dayType => {
            return dayType.start.name === startDay.name && dayType.end.name === endDay.name;
        });
    };

    let dayTypes;
    // Replace day types names to a default one for its correct display in Day Types section
    if (analysis.project_id) {
        dayTypes = analysis.day_types.map(rawDayType => {
            // Extract day type name, start/end day short names from raw string (example: "0: All Days (M-Su)")
            const match = rawDayType.match(/\d:(.*)\((\w+)-(\w+)\)/);
            const dayTypeName = match[1].trim();
            const startDay = getDayFromShortName(match[2]);
            const endDay = getDayFromShortName(match[3]);
            const defaultDayType = findDayTypeByRangeNames(startDay, endDay);

            return !defaultDayType || defaultDayType.name === dayTypeName
                ? rawDayType
                : rawDayType.replace(dayTypeName, defaultDayType.name);
        });
    } else {
        dayTypes = analysis.day_types
            .split(",")
            .map(rawDayType => {
                // Extract day type name, start/end day codes from raw string (example: "All Days|17")
                const match = rawDayType.match(/(.*)\|(\d)(\d)/);
                const dayTypeName = match[1].trim();
                const startDay = getDayFromCode(match[2]);
                const endDay = getDayFromCode(match[3]);
                const defaultDayType = findDayTypeByRangeNames(startDay, endDay);

                return !defaultDayType || defaultDayType.name === dayTypeName
                    ? rawDayType
                    : rawDayType.replace(dayTypeName, defaultDayType.name);
            })
            .join(",");
    }

    return {
        ...analysis,
        day_types: dayTypes,
        data_periods: [],
        date_ranges: defaultDataPeriods[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code].map(
            dateRange => ({
                start_date: moment(dateRange.startDate).format("MM/DD/YYYY"),
                end_date: moment(dateRange.endDate).format("MM/DD/YYYY"),
            }),
        ),
        project_type: ANALYSIS_TYPES.TT_TMC.id,
        travel_mode_type: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
    };
};

export const getTravelModeAndOutputTypeText = ({
    travelMode,
    calibration,
    country,
    isReadOnly,
}) => {
    let outputTypeLabel;
    if (calibration.shouldIgnoreSuffix) {
        outputTypeLabel = calibration.display;
    } else {
        const isBusOrRailMode = [MODES_OF_TRAVEL.BUS.code, MODES_OF_TRAVEL.RAIL.code].includes(
            travelMode.code,
        );
        const suffixes = travelMode.calibration.displaySuffixes;
        // Bus and Rail analyses in read-only modes should always display default calibration suffix
        const outputTypeSuffix =
            isBusOrRailMode && isReadOnly
                ? suffixes.default
                : suffixes[calibration.code]?.[country] ||
                  suffixes[calibration.code] ||
                  suffixes.default;

        outputTypeLabel = `${calibration.display}, ${outputTypeSuffix}`;
    }
    return `${travelMode.display} (${outputTypeLabel})`;
};

const getMonthNameFunc = monthFormat => monthIndex => {
    return moment().month(monthIndex).format(monthFormat);
};

export const getAnalysisAvailableYearPeriodsWithMonthData = ranges => {
    const yearMonthMap = {};

    const monthFormat = "MMM";
    const getMonthName = getMonthNameFunc(monthFormat);

    // Fill yearMonthMap based on each range
    ranges.forEach(({ startDate, endDate }) => {
        const start = moment(startDate);
        const end = moment(endDate);

        const current = start.clone();

        while (current.isSameOrBefore(end, "month")) {
            const year = current.year();
            const month = current.month(); // month + 1 to convert to 1-indexed

            if (!yearMonthMap[year]) {
                yearMonthMap[year] = new Set();
            }
            yearMonthMap[year].add(month);

            current.add(1, "month");
        }
    });

    const dateRanges = [];
    let continuousFullYears = [];

    const flushContinuousFullYearsToResult = () => {
        if (!continuousFullYears.length) {
            return;
        }
        if (continuousFullYears.length === 1) {
            dateRanges.push(`${continuousFullYears[0]} (All months)`);
        } else {
            dateRanges.push(
                `${continuousFullYears[0]} - ${
                    continuousFullYears[continuousFullYears.length - 1]
                } (All months)`,
            );
        }
        continuousFullYears = [];
    };

    // Convert yearMonthMap to the desired format
    Object.keys(yearMonthMap)
        .sort((a, b) => a - b)
        .forEach(_year => {
            const months = Array.from(yearMonthMap[_year]).sort((a, b) => a - b);

            const year = parseInt(_year, 10);

            if (months.length === 12) {
                if (
                    !continuousFullYears.length ||
                    continuousFullYears[continuousFullYears.length - 1] === year - 1
                ) {
                    continuousFullYears.push(year);
                } else {
                    flushContinuousFullYearsToResult();
                    continuousFullYears.push(year);
                }
            } else {
                flushContinuousFullYearsToResult();

                const monthRanges = [];
                let rangeStart = months[0];
                let rangeEnd = months[0];

                for (let i = 1; i < months.length; i++) {
                    if (months[i] === rangeEnd + 1) {
                        rangeEnd = months[i];
                    } else {
                        monthRanges.push(
                            rangeStart === rangeEnd
                                ? getMonthName(rangeStart)
                                : `${getMonthName(rangeStart)} - ${getMonthName(rangeEnd)}`,
                        );
                        rangeStart = months[i];
                        rangeEnd = months[i];
                    }
                }
                monthRanges.push(
                    rangeStart === rangeEnd
                        ? getMonthName(rangeStart)
                        : `${getMonthName(rangeStart)} - ${getMonthName(rangeEnd)}`,
                );

                dateRanges.push(`${year} (${monthRanges.join(", ")})`);
            }
        });

    flushContinuousFullYearsToResult();

    return dateRanges;
};

// Calculate consecutive AADT years
export const getAADTAvailableYearPeriods = availableYears => {
    const ranges = [];
    let yearStart = availableYears[0];
    let yearEnd = availableYears[0];

    for (let i = 1; i < availableYears.length; i++) {
        if (availableYears[i] === yearEnd + 1) {
            yearEnd = availableYears[i];
        } else {
            ranges.push(yearStart === yearEnd ? yearStart : `${yearStart} - ${yearEnd}`);
            yearStart = availableYears[i];
            yearEnd = availableYears[i];
        }
    }
    ranges.push(yearStart === yearEnd ? yearStart : `${yearStart} - ${yearEnd}`);

    return ranges.map(range => `${range} (All months)`);
};

const checkZoneCondition = (zone, condition) => {
    const { additionalProperty, property, value, exclude } = condition;

    if (!zone.hasOwnProperty(property)) return true;

    let propertyName = property;

    if (additionalProperty && zone.hasOwnProperty(additionalProperty)) {
        propertyName = additionalProperty;
    }

    if (Array.isArray(value)) {
        return exclude
            ? !arrayIncludes(value, zone[propertyName])
            : arrayIncludes(value, zone[propertyName]);
    }

    return exclude ? !isEqual(value, zone[propertyName]) : isEqual(value, zone[propertyName]);
};

export const filterZones = (zones, allowedZonesConditions) => {
    if (!allowedZonesConditions?.length) return zones;

    return zones.filter(zone =>
        allowedZonesConditions.every(condition => checkZoneCondition(zone, condition)),
    );
};
